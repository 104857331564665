import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import toast from "react-hot-toast";
import API from "../../../../config/axios";
import SearchIcon from "@mui/icons-material/Search";

function KnowledgeBaseFaq() {
  const [search, setSearch] = useState(null);
  const [allCategory, setAllCategory] = useState([]);
  const [question, setQuestion] = useState([]);

  const [expandedPanel, setExpandedPanel] = useState(null);

  const OrderArray = [
    "Instellingen en inrichting",
    "Contacten",
    "Offertes",
    "Projecten",
    "Urenregistratie",
    "Ritten",
    "Facturen",
    "Inkopen (Kosten)",
    "Bank",
    "Boekhouding",
    "Koppelingen",
    "Belastingaangifte",
    "Vertrouwen en verificatie",
    "Beveiliging",
    "Overig",
    "Inkomstenbelasting",
    "Hoe boek ik ?",
    "Handleiding",
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  const handleSearch = () => {
    const word = document.getElementById("search").value;
    if (word && word !== "") {
      setSearch(word);
    } else {
      setSearch(null);
    }
  };

  const filterDataByKeyword = (data, keyword) => {
    return data.filter((item) => {
      return item.question.toLowerCase().includes(keyword.toLowerCase());
    });
  };

  const groupQuestionsByType = () => {
    const groupedQuestions = {};

    OrderArray.forEach((type) => {
      const orderItem = allCategory.find((item) => item.title === type);
      if (orderItem) {
        groupedQuestions[orderItem._id || orderItem.id] = [];
      }
    });

    question.forEach((question) => {
      if (!groupedQuestions[question.questionType]) {
        groupedQuestions[question.questionType] = [];
      }
      groupedQuestions[`${question.questionType}`].push(question);
    });
    return groupedQuestions;
  };

  const groupedQuestions = groupQuestionsByType();

  const scollToTarget = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const getTypeList = (searchVal = "") => {
    // Cancel the previous request
    API.get("/public/faq/type")
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          const sortedData = resData.data.sort(function customSort(a, b) {
            const indexA = OrderArray.indexOf(a?.title);
            const indexB = OrderArray.indexOf(b?.title);

            if (indexA === -1 && indexB === -1) {
              return 0;
            } else if (indexA === -1) {
              return 1;
            } else if (indexB === -1) {
              return -1;
            } else {
              return indexA - indexB;
            }
          });
          setAllCategory(sortedData);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {});
  };
  const getFAQList = (searchVal = "") => {
    // Cancel the previous request
    API.get("/public/faq")
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setQuestion(resData.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {});
  };

  const decodeHTMLEntities = (input) => {
    return input.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
  };
  // const imagePath = (str) => {
  //   return (
  //     API.getUri() + "public" + str.split("/public")[1] ||
  //     API.defaults.baseURL + "public" + str.split("/public")[1]
  //   );
  // };

  useEffect(() => {
    getTypeList();
    getFAQList();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="pageStructure"
      style={{ paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px" }}
    >
      <div className="helpBanner">
        <div className="flex" style={{ justifyContent: "center" }}>
          <Typography
            variant="h4"
            className="titleUponDivider"
            sx={{
              color: "black",
              textDecoration: "none",
              fontStyle: "normal",
              fontWeight: "bold !important",
            }}
            gutterBottom
          >
            Hoe kunnen we je helpen?
          </Typography>
        </div>
        <Grid container justifyContent={"center"}>
          <Grid
            xs={10}
            md={5}
            lg={5}
            sm={10}
            sx={{
              border: "1px solid grey",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "10px",
              backgroundColor: "white",
            }}
          >
            <input
              type="text"
              className="faqSearch"
              id="search"
              autoComplete="off"
            />

            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: "2rem" }}>
        <Grid container justifyContent={"space-between"}>
          {allCategory.length > 0 ? (
            <Grid item xs={12} md={4} lg={2.5} sm={12}>
              <div
                style={{
                  borderRadius: "15px",
                  boxShadow:
                    "0px 1px 3px 1px rgba(208, 175, 136, 1),0px 1px 2px -1px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div className="stickyFaq">
                  {allCategory.map((topic, topicIndex) => (
                    <MenuItem
                      className="faqTitle"
                      key={topicIndex}
                      sx={{ paddingLeft: "2rem" }}
                      onClick={() => scollToTarget(topic._id)}
                    >
                      {/* <img
                    src={imagePath(topic["icon"])}
                    style={{ display: "inline-block" }}
                    height={"16px"}
                    width={"16px"}
                    alt=""
                  /> */}
                      {topic?.title}
                    </MenuItem>
                  ))}
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}

          <Grid item xs={12} md={7} lg={8.5} sm={12}>
            {Object.keys(groupedQuestions).length > 0 ? (
              <div className="scroll" style={{ maxHeight: "530px", overflow: "auto" }}>
                {Object.keys(groupedQuestions).map((key) => (
                  <div key={key} id={key}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ color: "#d0af88" }}
                    >
                      {allCategory.find((topic) => topic._id === key)?.title}
                    </Typography>
                    {/* <div
                  className="danger"
                  dangerouslySetInnerHTML={{
                    __html: decodeHTMLEntities(
                      allCategory.find((topic) => topic._id === key).description
                    ),
                  }}
                /> */}

                    {search && search !== "" ? (
                      filterDataByKeyword(groupedQuestions[key], search)
                        .length > 0 ? (
                        filterDataByKeyword(groupedQuestions[key], search).map(
                          (question, questionIndex) => (
                            <Accordion
                              key={questionIndex}
                              sx={{
                                border: "1px solid grey",
                                borderRadius: "10px !important",
                                margin: "1rem 0px",
                              }}
                              expanded={
                                expandedPanel ===
                                `faq-header-${questionIndex}-${key}`
                              }
                              onChange={handleChange(
                                `faq-header-${questionIndex}-${key}`
                              )}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`faq-content-${questionIndex}-${key}`}
                                id={`faq-header-${questionIndex}-${key}`}
                              >
                                <Typography variant="subtitle1">
                                  {question.question}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography sx={{ marginBottom: "1rem" }}>
                                  <div
                                    className="danger"
                                    dangerouslySetInnerHTML={{
                                      __html: decodeHTMLEntities(question.html),
                                    }}
                                  />
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          )
                        )
                      ) : (
                        <div className="faqSearchNoData">
                          Geen resultaten in{" "}
                          <strong>
                            {
                              allCategory.find((topic) => topic._id === key)
                                ?.title
                            }
                          </strong>
                        </div>
                      )
                    ) : groupedQuestions[key].length > 0 ? (
                      groupedQuestions[key].map((question, questionIndex) => (
                        <Accordion
                          key={questionIndex}
                          sx={{
                            border: "1px solid grey",
                            borderRadius: "10px !important",
                            margin: "1rem 0px",
                          }}
                          expanded={
                            expandedPanel ===
                            `faq-header-${questionIndex}-${key}`
                          }
                          onChange={handleChange(
                            `faq-header-${questionIndex}-${key}`
                          )}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`faq-content-${questionIndex}-${key}`}
                            id={`faq-header-${questionIndex}-${key}`}
                          >
                            <Typography variant="subtitle1">
                              {question.question}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography sx={{ marginBottom: "1rem" }}>
                              <div
                                className="danger"
                                dangerouslySetInnerHTML={{
                                  __html: decodeHTMLEntities(question.html),
                                }}
                              />
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))
                    ) : (
                      <div className="faqSearchNoData">
                        Er zijn geen vragen gevonden met betrekking tot deze
                        categorie.
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <h1 style={{ margin: 0 }}>Er zijn geen vragen gevonden.</h1>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default KnowledgeBaseFaq;
