import { Button, Grid, Typography } from "@mui/material";
import Carousel from "react-simply-carousel";
import { useEffect, useState } from "react";
import AppsAndDeals from "../../../dashboard/AppsAndDeals";
import TextInput from "../../../../components/form/TextInput";
import { useForm } from "react-hook-form";
import API from "../../../../config/axios";
import { toast } from "react-hot-toast";
import { highlightField } from "../../../../utils/Commons";
import DoneIcon from "@mui/icons-material/Done";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaymentsIcon from "@mui/icons-material/Payments";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LinkIcon from "@mui/icons-material/Link";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PhotoFilterIcon from "@mui/icons-material/PhotoFilter";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import WbIridescentIcon from "@mui/icons-material/WbIridescent";
import EuroIcon from "@mui/icons-material/Euro";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

function HomePage({ setPage }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeSlide, setActiveSlide] = useState(0);
  // eslint-disable-next-line
  const [inputErrors, setInputErrors] = useState({});
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(t("customers.email_required"))
      .email(t("customers.email_invalid")),
  });

  const { control, handleSubmit } = useForm({
    // defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const requestStartTime = Date.now();

    API.post("public/newsLetter", data)
      .then((res) => {
        const resData = res.data;

        setInputErrors({});

        if (resData.status === true) {
          setIsSubmitted(true);
          toast.success("You are now subscribed");
        } else if (resData.status === false) {
          setIsSubmitted(true);
          toast.success("You are already subscribed");
        } else {
          if (resData && resData.type === "VALIDATION") {
            setInputErrors(resData.error);
            try {
              highlightField(Object.keys(resData.error)[0]);
            } catch (error) {
              console.warn("Field cannot be highlighted");
            }
          } else {
            toast.error(resData?.error?.message || "Something went wrong");
          }
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        const requestEndTime = Date.now();
        const timeOutMileSeconds = requestEndTime - requestStartTime;

        if (timeOutMileSeconds > 300) {
          setIsLoading(false);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      });
  };

  const items = [
    <div
      style={{
        margin: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        gap: "10px",
        marginRight: "2rem",
        width: "300px",
      }}
    >
      <div
        style={{
          flex: 1,
          background: "white",
          padding: "20px",
          borderRadius: "15px",
          border: "1px solid #d0af88",
          boxSizing: "border-box",
          boxShadow:
            "0px 1px 3px 1px rgba(208, 175, 136, 1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1) !important",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <LocalOfferIcon
            sx={{
              fontSize: 60,
              color: "#d0af88",
              border: "2px solid #d0af88",
              borderRadius: "50%",
              padding: "5px",
              background: "white",
            }}
          />
          <Typography variant="h6" style={{ marginLeft: "10px" }}>
            <strong>Offertes </strong>
          </Typography>
        </div>
        <Typography variant="body1">
          Offertes maken en versturen, opties aanbieden, online accepteren en
          factureren van offertes.
        </Typography>
      </div>
    </div>,
    <div
      style={{
        margin: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        gap: "10px",
        marginRight: "2rem",
        width: "300px",
      }}
    >
      <div
        style={{
          flex: 1,
          background: "white",
          padding: "20px",
          borderRadius: "15px",
          border: "1px solid #d0af88",
          boxSizing: "border-box",
          boxShadow:
            "0px 1px 3px 1px rgba(208, 175, 136, 1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1) !important",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <PhotoFilterIcon
            sx={{
              fontSize: 60,
              color: "#d0af88",
              border: "2px solid #d0af88",
              borderRadius: "50%",
              padding: "5px",
              background: "white",
            }}
          />
          <Typography variant="h6" style={{ marginLeft: "10px" }}>
            <strong>Projecten </strong>
          </Typography>
        </div>
        <Typography variant="body1">
          Inrichten van projectenen ORT percentages toevoegen
        </Typography>
      </div>
    </div>,
    <div
      style={{
        margin: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        gap: "10px",
        marginRight: "2rem",
        width: "300px",
      }}
    >
      <div
        style={{
          flex: 1,
          background: "white",
          padding: "20px",
          borderRadius: "15px",
          border: "1px solid #d0af88",
          boxSizing: "border-box",
          boxShadow:
            "0px 1px 3px 1px rgba(208, 175, 136, 1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1) !important",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <MoreTimeIcon
            sx={{
              fontSize: 60,
              color: "#d0af88",
              border: "2px solid #d0af88",
              borderRadius: "50%",
              padding: "5px",
              background: "white",
            }}
          />
          <Typography variant="h6" style={{ marginLeft: "10px" }}>
            <strong>Urenregistratie </strong>
          </Typography>
        </div>
        <Typography variant="body1">
          Je gewerkte uren registreren en facturen.
        </Typography>
      </div>
    </div>,
    <div
      style={{
        margin: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        gap: "10px",
        marginRight: "2rem",
        width: "300px",
      }}
    >
      <div
        style={{
          flex: 1,
          background: "white",
          padding: "20px",
          borderRadius: "15px",
          border: "1px solid #d0af88",
          boxSizing: "border-box",
          boxShadow:
            "0px 1px 3px 1px rgba(208, 175, 136, 1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1) !important",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <WbIridescentIcon
            sx={{
              fontSize: 60,
              color: "#d0af88",
              border: "2px solid #d0af88",
              borderRadius: "50%",
              padding: "5px",
              background: "white",
            }}
          />
          <Typography variant="h6" style={{ marginLeft: "10px" }}>
            <strong>Ritten </strong>
          </Typography>
        </div>
        <Typography variant="body1">
          bijhouden van je ritenregistratie of reiskosten vergoeding
        </Typography>
      </div>
    </div>,
    <div
      style={{
        margin: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        gap: "10px",
        marginRight: "2rem",
        width: "300px",
      }}
    >
      <div
        style={{
          flex: 1,
          background: "white",
          padding: "20px",
          borderRadius: "15px",
          border: "1px solid #d0af88",
          boxSizing: "border-box",
          boxShadow:
            "0px 1px 3px 1px rgba(208, 175, 136, 1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1) !important",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <EuroIcon
            sx={{
              fontSize: 60,
              color: "#d0af88",
              border: "2px solid #d0af88",
              borderRadius: "50%",
              padding: "5px",
              background: "white",
            }}
          />
          <Typography variant="h6" style={{ marginLeft: "10px" }}>
            <strong>Facturatie </strong>
          </Typography>
        </div>
        <Typography variant="body1">
          Facturen maken, versturen en laten betalen in binnen- en buitenland.
        </Typography>
      </div>
    </div>,
    <div
      style={{
        margin: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        gap: "10px",
        marginRight: "2rem",
        width: "300px",
      }}
    >
      <div
        style={{
          flex: 1,
          background: "white",
          padding: "20px",
          borderRadius: "15px",
          border: "1px solid #d0af88",
          boxSizing: "border-box",
          boxShadow:
            "0px 1px 3px 1px rgba(208, 175, 136, 1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1) !important",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <PaymentsIcon
            sx={{
              fontSize: 60,
              color: "#d0af88",
              border: "2px solid #d0af88",
              borderRadius: "50%",
              padding: "5px",
              background: "white",
            }}
          />
          <Typography variant="h6" style={{ marginLeft: "10px" }}>
            <strong>Inkopen (Kosten) </strong>
          </Typography>
        </div>
        <Typography variant="body1">
          Inkoopfacturen en bonnen toevoegen, betalingen registreren en
          memoriaalboekingen maken.
        </Typography>
      </div>
    </div>,
    <div
      style={{
        margin: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        gap: "10px",
        marginRight: "2rem",
        width: "300px",
      }}
    >
      <div
        style={{
          flex: 1,
          background: "white",
          padding: "20px",
          borderRadius: "15px",
          border: "1px solid #d0af88",
          boxSizing: "border-box",
          boxShadow:
            "0px 1px 3px 1px rgba(208, 175, 136, 1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1) !important",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <AccountBalanceIcon
            sx={{
              fontSize: 60,
              color: "#d0af88",
              border: "2px solid #d0af88",
              borderRadius: "50%",
              padding: "5px",
              background: "white",
            }}
          />
          <Typography variant="h6" style={{ marginLeft: "10px" }}>
            <strong>Bank </strong>
          </Typography>
        </div>
        <Typography variant="body1">
          Transacties toevoegen, verschillen oplossen en specifieke situaties.
        </Typography>
      </div>
    </div>,
    <div
      style={{
        margin: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        gap: "10px",
        marginRight: "2rem",
        width: "300px",
      }}
    >
      <div
        style={{
          flex: 1,
          background: "white",
          padding: "20px",
          borderRadius: "15px",
          border: "1px solid #d0af88",
          boxSizing: "border-box",
          boxShadow:
            "0px 1px 3px 1px rgba(208, 175, 136, 1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1) !important",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <AccountBoxIcon
            sx={{
              fontSize: 60,
              color: "#d0af88",
              border: "2px solid #d0af88",
              borderRadius: "50%",
              padding: "5px",
              background: "white",
            }}
          />
          <Typography variant="h6" style={{ marginLeft: "10px" }}>
            <strong>Boekhouding </strong>
          </Typography>
        </div>
        <Typography variant="body1">
          Je boekhouding in Dashbook, zakelijk en privé verwerken en uitleg over
          de rapporten.
        </Typography>
      </div>
    </div>,
    <div
      style={{
        margin: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        gap: "10px",
        marginRight: "2rem",
        width: "300px",
      }}
    >
      <div
        style={{
          flex: 1,
          background: "white",
          padding: "20px",
          borderRadius: "15px",
          border: "1px solid #d0af88",
          boxSizing: "border-box",
          boxShadow:
            "0px 1px 3px 1px rgba(208, 175, 136, 1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1) !important",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <LinkIcon
            sx={{
              fontSize: 60,
              color: "#d0af88",
              border: "2px solid #d0af88",
              borderRadius: "50%",
              padding: "5px",
              background: "white",
            }}
          />
          <Typography variant="h6" style={{ marginLeft: "10px" }}>
            <strong>Koppelingen </strong>
          </Typography>
        </div>
        <Typography variant="body1">
          De koppelingen gemaakt door derden, om andere programma's samen met
          Dashbook te gebruiken.
        </Typography>
      </div>
    </div>,
  ];

  const bankLinkItems = [
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/paypal.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",

          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/abn.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",

          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/asn-bank.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",

          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/bunq.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",

          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/ing-bank.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",

          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/knab.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",

          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/mollie.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",

          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/n26.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",

          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/rabobank.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",

          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/regiobank.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",

          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/revolut.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",

          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/sns.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",

          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/triodos-bank.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
    <div>
      <div
        style={{
          marginRight: "40px",
          marginBottom: "20px",
          // backgroundColor: "white",
          width: 160,
          height: 160,
          border: "3px solid #d0af88",
          borderRadius: "10px",
          textAlign: "center",
          boxSizing: "border-box",
          position: "relative",
          color: "black",

          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Light grey box shadow
        }}
      >
        <img
          src={"/BankLink/wise.png"}
          style={{
            borderRadius: "10px",
            maxWidth: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            maxHeight: "100%", // Ensure the image doesn't exceed the dimensions of the parent div
            position: "absolute", // Position the image absolutely within the parent div
            top: "50%", // Align the image vertically centered
            left: "50%", // Align the image horizontally centered
            transform: "translate(-50%, -50%)", // Center the image properly
          }}
          alt="Paypal Logo"
        />
      </div>
    </div>,
  ];

  const applyAnimation = () => {
    const sections = document.querySelectorAll('[class^="section-"]');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const position = section.getBoundingClientRect();

      // If the top of the section is in the viewport
      if (position.top < window.innerHeight && position.bottom > 0) {
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "slideInLeft 1s ease forwards";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "slideInRight 1s ease forwards";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "slideInUp 1s ease forwards";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "slideInDown 1s ease forwards";
        }
      } else {
        // If the section is out of view, revert the animation
        const leftDiv = section.querySelector(".left-div");
        if (leftDiv) {
          leftDiv.style.animation = "none";
        }

        const rightDiv = section.querySelector(".right-div");
        if (rightDiv) {
          rightDiv.style.animation = "none";
        }

        const upDiv = section.querySelector(".up-div");
        if (upDiv) {
          upDiv.style.animation = "none";
        }

        const downDiv = section.querySelector(".down-div");
        if (downDiv) {
          downDiv.style.animation = "none";
        }
      }
    }
  };

  useEffect(() => {
    // Check position on scroll
    window.addEventListener("scroll", applyAnimation);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", applyAnimation);
    };
  }, []);

  return (
    <div style={{ backgroundColor: "rgb(243, 243, 243)" }}>
      <div
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          // background: "rgb(234,238,225)",
          background:
            "linear-gradient(90deg, rgba(234,238,225,1) 0%, rgba(245,226,205,1) 100%)",
          position: "relative",
          textAlign: "center",
          padding: "6rem 2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img
          src={"./Professional/WordLid.png"}
          style={{
            position: "absolute",
            top: "2rem",
            left: "2rem",
            height: "auto",
            width: "15vw",
          }}
          alt="bannerImage"
        />
        <img
          src={"./Professional/DashbookRound.png"}
          style={{
            position: "absolute",
            left: "2rem",
            bottom: "2rem",
            height: "auto",
            width: "8vw",
          }}
          alt="bannerImage"
        />
        <img
          src={"./Professional/DashbookHalfLaptop.png"}
          style={{
            position: "absolute",
            right: "0px",
            bottom: "2rem",
            height: "auto",
            width: "20vw",
          }}
          alt="bannerImage"
        />
        <Typography
          variant="h2"
          sx={{
            color: "#D0AF88",
            fontWeight: "bold",
            fontFamily: "'Poppins', sans-serif",
          }}
          gutterBottom
        >
          Zeker van je Boekhouding.
        </Typography>
        <p className="poppins-title" style={{ marginBottom: "1rem" }}>
          Zeker van je Zaak.
        </p>
        <h6 className="poppins-title" style={{ marginBottom: "2rem" }}>
          Dit is hét moment om te beginnen
        </h6>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <button
            className="buttonEffect"
            onClick={() => navigate("/dashboard")}
          >
            Jullie hebben me overtuigd
          </button>
          <button
            className="buttonEffect"
            onClick={() => navigate("/dashboard")}
          >
            Gratis uitproberen
          </button>
        </div>
      </div>

      <div
        style={{
          textAlign: "center",
          padding: "4rem 0px",
          backgroundColor: "white",
        }}
        className="section-1"
      >
        <Grid container justifyContent={"space-evenly"}>
          <Grid item xs={12} md={4.5} sm={12} sx={{ marginBottom: "2rem" }}>
            <div
              className="left-div card"
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "white",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: "#d0af88",
                  fontSize: "1.5rem",
                  fontWeight: "400",
                  textAlign: "center",
                }}
                gutterBottom
              >
                Voordelen van Dashbook
              </Typography>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Doen mee met de beste van beste
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{ color: "black" }}
                >
                  Speciaal voor starters en zzp'ers
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{ color: "black" }}
                >
                  Super simpel
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  Al vanaf € 5, -
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{ color: "black" }}
                >
                  Geen verborgen kosten
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{ color: "black" }}
                >
                  Veilig- secure cloud
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  Gratis bankkoppeling
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6.5}
            sm={12}
            sx={{ alignSelf: "center", marginBottom: "2rem" }}
          >
            <div
              className="right-div card"
              style={{
                backgroundColor: "white",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: "#d0af88",
                  fontSize: "1.5rem",
                  fontWeight: "400",
                  textAlign: "center",
                }}
                gutterBottom
              >
                Het enige programma met ORT-uren
              </Typography>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Nooit meer worstelen met complexe ORT-uren
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    color: "black",
                  }}
                >
                  Volledig automatisch berekent Dashbook de juiste toeslagen
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    color: "black",
                  }}
                >
                  Eenvoudig integreer uw ORT-uren in uw urenregistratie
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    color: "black",
                  }}
                >
                  Zorgeloos voldoet u aan alle wettelijke eisen.
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  De perfecte oplossing voor diverse branches:
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    color: "black",
                  }}
                >
                  Zorg
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    color: "black",
                  }}
                >
                  Beveiliging
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    color: "black",
                  }}
                >
                  Brandwacht
                </Typography>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <DoneIcon
                  style={{
                    height: "15px",
                    margin: "6px 0px",
                    color: "green",
                  }}
                />
                <Typography
                  variant="overline"
                  display="block"
                  style={{
                    color: "black",
                    textAlign: "left",
                  }}
                >
                  En meer: Dashbook is geschikt voor iedereen die te maken heeft
                  met ORT-uren.
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div style={{ margin: "40px" }}>
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            marginBottom: "3rem",
            color: "#d0af88",
            fontSize: "2.25rem",
          }}
          gutterBottom
        >
          <strong>
            Alles wat een ondernemer nodig heeft voor boekhouden en facturatie
          </strong>
        </Typography>
        <Carousel
          autoplay={true} // Enable autoplay
          autoplaySpeed={5000} // Set the autoplay speed in milliseconds (adjust as needed for slower speed)
          autoplayDirection="forward" // Set the autoplay direction to forward for clockwise rotation
          infinite={true} // Enable infinite looping
          containerProps={{
            style: {
              width: "100%",
              justifyContent: "space-between",
              userSelect: "none",
              maxWidth: "none",
              marginRight: "2px",
            },
          }}
          swipeTreshold={60}
          activeSlideIndex={activeSlide}
          activeSlideProps={{
            style: {
              // background: "white",
            },
          }}
          onRequestChange={setActiveSlide}
          forwardBtnProps={{
            show: false,
            // children: ">",
            // style: {
            //   width: 60,
            //   height: 60,
            //   minWidth: 60,
            //   alignSelf: "center",
            // },
          }}
          backwardBtnProps={{
            show: false,
            // children: "<",
            // style: {
            //   width: 60,
            //   height: 60,
            //   minWidth: 60,
            //   alignSelf: "center",
            // },
          }}
          dotsNav={{
            show: false,
            // itemBtnProps: {
            //   style: {
            //     height: 16,
            //     width: 16,
            //     borderRadius: "50%",
            //     border: 0,
            //     background: "black",
            //   },
            // },
          }}
          itemsToShow={6}
          speed={7700}
          // centerMode
        >
          {items}
        </Carousel>
      </div>

      <div
        style={{
          backgroundColor: "white",
          padding: "4rem",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            color: "#D0AF88",
            fontWeight: "300 !important",
            textDecoration: "underline",
            fontStyle: "italic",
            marginBottom: "3rem",
          }}
          gutterBottom
        >
          De partners van Dashbook
        </Typography>
        <p className="poppins-title" style={{ marginBottom: "2rem" }}>
          In de Dashbook Store vind je alles wat je nodig hebt om je taken
          efficiënter en effectiever te realiseren. We bieden een breed scala
          aan producten en diensten van onze partners, die net als wij alleen
          het allerbeste voor jou als zelfstandig ondernemer willen. Ons
          assortiment:
        </p>

        <Grid container justifyContent={"space-evenly"}>
          <Grid
            item
            xs={12}
            md={2.8}
            sm={5.5}
            sx={{
              border: "1px solid rgb(243, 243, 243)",
              borderRadius: "10px",
              padding: "1rem 2rem",
              backgroundColor: "rgba(208,175,136,0.3)",
              margin: "0.5rem 0px",
            }}
          >
            <Typography
              variant="overline"
              display="block"
              align="center"
              sx={{
                backgroundColor: "#D0AF88",
                color: "white",
                marginBottom: "1rem",
              }}
            >
              Marketingtools
            </Typography>
            <p
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontSize: "0.9rem",
                textAlign: "center",
              }}
            >
              Vergroot je online zichtbaarheid, trek nieuwe klanten aan en boost
              je conversie met professionele marketingtools.
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.8}
            sm={5.5}
            sx={{
              border: "1px solid rgb(243, 243, 243)",
              borderRadius: "10px",
              padding: "1rem 2rem",
              backgroundColor: "rgba(208,175,136,0.3)",
              margin: "0.5rem 0px",
            }}
          >
            <Typography
              variant="overline"
              display="block"
              align="center"
              sx={{
                backgroundColor: "#D0AF88",
                color: "white",
                marginBottom: "1rem",
              }}
            >
              Verzekeringen{" "}
            </Typography>
            <p
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontSize: "0.9rem",
                textAlign: "center",
              }}
            >
              In de store vind je diverse verzekeringen die speciaal zijn
              afgestemd op de behoeften van zelfstandig ondernemers
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.8}
            sm={5.5}
            sx={{
              border: "1px solid rgb(243, 243, 243)",
              borderRadius: "10px",
              padding: "1rem 2rem",
              backgroundColor: "rgba(208,175,136,0.3)",
              margin: "0.5rem 0px",
            }}
          >
            <Typography
              variant="overline"
              display="block"
              align="center"
              sx={{
                backgroundColor: "#D0AF88",
                color: "white",
                marginBottom: "1rem",
              }}
            >
              Tijdbesparende diensten{" "}
            </Typography>
            <p
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontSize: "0.9rem",
                textAlign: "center",
              }}
            >
              Besteed je kostbare tijd aan waar je écht goed in bent en
              outsource taken zoals boekhouding of webdesign.
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.8}
            sm={5.5}
            sx={{
              border: "1px solid rgb(243, 243, 243)",
              borderRadius: "10px",
              padding: "1rem 2rem",
              backgroundColor: "rgba(208,175,136,0.3)",
              margin: "0.5rem 0px",
            }}
          >
            <Typography
              variant="overline"
              display="block"
              align="center"
              sx={{
                backgroundColor: "#D0AF88",
                color: "white",
                marginBottom: "1rem",
              }}
            >
              Trainingen en workshops
            </Typography>
            <p
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontSize: "0.9rem",
                textAlign: "center",
              }}
            >
              Ontwikkel je vaardigheden en blijf op de hoogte van de laatste
              trends met inspirerende trainingen en workshops.
            </p>
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          padding: "2rem 4rem",
          backgroundColor: "white",
          paddingTop: "0rem",
        }}
      >
        <Grid container justifyContent={"space-between"}>
          <AppsAndDeals />
        </Grid>
      </div>

      <div style={{ padding: "2rem", paddingBottom: "0px" }}>
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            marginBottom: "3rem",
            color: "#d0af88",
            fontSize: "2.25rem",
          }}
          gutterBottom
        >
          <strong>
            Wil je ook onze partner worden? Stuur dan een mail naar
            <Link
              to="mailto:info@dashbookofferte"
              style={{ color: "#d0af88", textDecoration: "none" }}
            >
              info@dashbook.nl
            </Link>{" "}
          </strong>
        </Typography>
      </div>
      <div style={{ margin: "40px" }}>
        <Carousel
          autoplay={true}
          autoplaySpeed={5000}
          autoplayDirection="forward"
          infinite={true}
          containerProps={{
            style: {
              width: "auto",
              margin: "auto",
              justifyContent: "space-between",
              userSelect: "none",
              maxWidth: "none",
            },
          }}
          swipeTreshold={60}
          activeSlideIndex={activeSlide}
          activeSlideProps={{
            style: {
              // background: "white",
            },
          }}
          onRequestChange={setActiveSlide}
          forwardBtnProps={{
            children: ">",
            style: {
              alignSelf: "center",
              display: "none",
            },
          }}
          backwardBtnProps={{
            children: "<",
            style: {
              margin: 20,
              width: 60,
              alignSelf: "center",
              display: "none",
            },
          }}
          dotsNav={{
            show: false,
          }}
          itemsToShow={10}
          speed={7700}
          centerMode
        >
          {bankLinkItems}
        </Carousel>
      </div>
      <div
        style={{
          padding: "4rem",
          paddingBottom: "3rem",
          backgroundColor: "white",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#D0AF88",
            fontFamily: "'Poppins'",
          }}
          gutterBottom
        >
          Zo goed als alles geautomatiseerd
        </Typography>
        <Grid container justifyContent={"space-evenly"}>
          <Grid item xs={12}>
            <Typography
              className="poppins-title"
              sx={{ textAlign: "left", marginBottom: "1rem" }}
              variant="h2"
            >
              Minder stress. Meer tijd voor je business.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className="poppins-title"
              sx={{ textAlign: "center !important" }}
              variant="body"
              gutterBottom
            >
              Een boekhoudprogramma voor ieder type ondernemer:
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent={"space-between"}>
              <Grid
                item
                xs={12}
                md={3.8}
                sx={{
                  border: "1px solid rgb(243, 243, 243)",
                  borderRadius: "10px",
                  padding: "1rem 2rem",
                  backgroundColor: "rgba(208,175,136,0.3)",
                  margin: "2rem 0px",
                }}
              >
                <Typography
                  variant="overline"
                  display="block"
                  align="center"
                  sx={{
                    backgroundColor: "#D0AF88",
                    color: "white",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  Boekhoudprogramma Starters
                </Typography>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "1rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  zeker van een juiste boekhouding als starter.
                </p>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "0.9rem",
                  }}
                >
                  Met Dasbook weet je als startende ondernemer zeker dat jouw
                  administratie correct is. Ervaar nu zelf.
                </p>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "0.9rem",
                  }}
                >
                  * Je eerste btw-aangifte binnen 2 min. geregeld
                </p>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "0.9rem",
                  }}
                >
                  * Vrijwel je gehele boekhouding is geautomatiseerd
                </p>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "0.9rem",
                  }}
                >
                  * Factureren in een handomdraai
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                md={3.8}
                sx={{
                  border: "1px solid rgb(243, 243, 243)",
                  borderRadius: "10px",
                  padding: "1rem 2rem",
                  backgroundColor: "rgba(208,175,136,0.3)",
                  margin: "2rem 0px",
                }}
              >
                <Typography
                  variant="overline"
                  display="block"
                  align="center"
                  sx={{
                    backgroundColor: "#D0AF88",
                    color: "white",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  Boekhoudprogramma ZZP
                </Typography>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "1rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Zeker van een juiste boekhouding.{" "}
                </p>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "0.9rem",
                  }}
                >
                  In minder dan 10 minuten per week. Super eenvoudig
                  boekhoudprogramma voor ZZP
                </p>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "0.9rem",
                  }}
                >
                  * Razendsnel boekhouden
                </p>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "0.9rem",
                  }}
                >
                  * Foutloze boekhouding
                </p>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "0.9rem",
                  }}
                >
                  * Bespaar tijd en geld
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                md={3.8}
                sx={{
                  border: "1px solid rgb(243, 243, 243)",
                  borderRadius: "10px",
                  padding: "1rem 2rem",
                  backgroundColor: "rgba(208,175,136,0.3)",
                  margin: "2rem 0px",
                }}
              >
                <Typography
                  variant="overline"
                  display="block"
                  align="center"
                  sx={{
                    backgroundColor: "#D0AF88",
                    color: "white",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  Boekhoudprogramma MKB
                </Typography>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "1rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Zeker van controle en een complete oplossing.
                </p>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "0.9rem",
                  }}
                >
                  Altijd inzicht in en controle over je MKB boekhouding - met
                  slechts een paar handelingen per week.
                </p>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "0.9rem",
                  }}
                >
                  * Constant geüpdatete cijfers en overzichten
                </p>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "0.9rem",
                  }}
                >
                  * Zo goed als volledig geautomatiseerd
                </p>
                <p
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "0.9rem",
                  }}
                >
                  * Werk efficiënt samen met je team
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div
        style={{ padding: "4rem", textAlign: "center", paddingBottom: "3rem" }}
      >
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid
            item
            md={4}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <div className="mb2mobile">
              <p className="poppins-title" style={{ marginBottom: "0px" }}>
                Maak en verstuur facturen
              </p>
              <Typography
                variant="body1"
                sx={{
                  marginTop: "0.5rem",
                  fontSize: "0.9rem",
                  fontFamily: "'Poppins'",
                }}
                gutterBottom
              >
                Klaar met een opdracht? Maak en verstuur moeiteloos facturen en
                ontvang zo snel mogelijk je geld.
              </Typography>
            </div>
            <div className="mb2mobile">
              <p className="poppins-title" style={{ marginBottom: "0px" }}>
                Overal ter wereld inzicht
              </p>
              <Typography
                variant="body1"
                sx={{
                  marginTop: "0.5rem",
                  fontSize: "0.9rem",
                  fontFamily: "'Poppins'",
                }}
                gutterBottom
              >
                Wees overal waar je online bent op de hoogte van je resultaten,
                banksaldi en openstaande facturen.
              </Typography>
            </div>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <img
              src={"./Professional/dashbookPicture.png"}
              style={{
                height: "auto",
                width: "100%",
              }}
              alt="DashbookPicture"
            />
          </Grid>
          <Grid
            item
            md={4}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <div className="mb2mobile">
              <p className="poppins-title" style={{ marginBottom: "0px" }}>
                Scan direct bonnetjes
              </p>
              <Typography
                variant="body1"
                sx={{
                  marginTop: "0.5rem",
                  fontSize: "0.9rem",
                  fontFamily: "'Poppins'",
                }}
                gutterBottom
              >
                Bonnetje ontvangen? Maak er een foto van via de app en gooi het
                bonnetje meteen in de schoendoos.
              </Typography>
            </div>
            <div className="mb2mobile">
              <p className="poppins-title" style={{ marginBottom: "0px" }}>
                Gelijk je uurtjes bijwerken
              </p>
              <Typography
                variant="body1"
                sx={{
                  marginTop: "0.5rem",
                  fontSize: "0.9rem",
                  fontFamily: "'Poppins'",
                }}
                gutterBottom
              >
                Loop nooit meer inkomsten door vergeten uren mis en registreer
                ze gelijk onder het juiste project.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          textAlign: "center",
          padding: "4rem",
          paddingBottom: "4rem",
          backgroundColor: "white",
        }}
      >
        <Grid container justifyContent="space-around">
          <Grid item xs={12} md={5} sm={12} sx={{ alignSelf: "center" }}>
            <Typography
              variant="h4"
              sx={{
                color: "#D0AF88",
                fontFamily: "'Poppins'",
                textAlign: "left",
              }}
              gutterBottom
            >
              Hulp nodig?{" "}
            </Typography>
            <Typography
              className="poppins-title"
              sx={{ textAlign: "left", marginBottom: "1rem" }}
              variant="h3"
            >
              Geen zorgen, je staat er niet alleen voor.
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontFamily: "'Poppins'",
                textAlign: "left",
                marginBottom: "1rem",
              }}
            >
              in onze kennisbank staan de antwoorden op de meest gestelde
              vragen. Krijg je liever support via e-mail? Dan zit ons
              supportteam voor je klaar. Stuur een mail naar:&nbsp;
              <Link to="mailto:support@dashbook.nl">
                support@Dashbook.nl
              </Link>{" "}
              en wij helpen je verder.
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontFamily: "'Poppins'",
                textAlign: "left",
                marginBottom: "1rem",
              }}
            >
              Maak gebruik van onze{" "}
              <a
                href="/#"
                onClick={() => {
                  setPage("back_office_service");
                }}
                style={{
                  textDecoration: "underline",
                  color: "#0000EE",
                  cursor: "pointer",
                }}
              >
                back office
              </a>
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontFamily: "'Poppins'",
                textAlign: "left",
              }}
            >
              Of je een boekhouder of accountant in contact zodat hij je mee kan
              helpen met je administratie en adviseren
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sm={12} sx={{ alignSelf: "center" }}>
            <img
              src={"./Professional/HelpImage.jpg"}
              style={{ maxWidth: "100%" }}
              alt="helpImage"
            />
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        justifyContent="space-between"
        sx={{
          padding: "3rem",
          paddingBottom: "4rem",
        }}
      >
        <Grid item xs={12} md={7} sm={12} sx={{ alignSelf: "center" }}>
          <div>
            <Typography
              variant="h4"
              sx={{
                color: "#D0AF88",
                fontFamily: "'Poppins'",
              }}
              gutterBottom
            >
              Meld je aan voor onze nieuwsbrief
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontFamily: "'Poppins'",
              }}
            >
              Blijf op de hoogte van updates en ons online magazine.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "'Poppins'",
              }}
              gutterBottom
            >
              {"Geen spam, beloofd... :)"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4.5} sm={12} sx={{ alignSelf: "center" }}>
          <div
            className="card"
            style={{
              backgroundColor: "white",
              width: "100%",
              display: "block",
            }}
          >
            {isSubmitted === false && (
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container>
                    <Grid item xs={12} md={8} sx={{ alignSelf: "center" }}>
                      <TextInput
                        name="email"
                        placeholder="E-mail"
                        control={control}
                      ></TextInput>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sx={{ textAlign: "center", marginTop: "0.4rem" }}
                      alignSelf={"center"}
                    >
                      <Button variant="contained" color="info" type="submit">
                        Schrijf in
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: "0.5rem",
                    fontSize: "0.9rem",
                    fontFamily: "'Poppins'",
                  }}
                  gutterBottom
                >
                  We care about your data in our&nbsp;
                  <a
                    href="/#"
                    style={{
                      textDecoration: "underline",
                      color: "#0000EE",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPage("privacy_declaration");
                    }}
                    // target="_blank"
                  >
                    privacy policy.
                  </a>
                </Typography>
              </>
            )}
            {isSubmitted === true && (
              <Grid container>
                <TaskAltIcon
                  sx={{
                    fontSize: 60,
                    // border: "2px solid #d0af88",
                    // borderRadius: "50%",
                    padding: "5px",
                    background: "white",
                    margin: "5PX",
                  }}
                />
                <h4 style={{ marginLeft: "2rem" }}>Uw e-mail is verzonden</h4>
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default HomePage;
